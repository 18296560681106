import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'react-emotion';
import useScrollPosition from '@react-hook/window-scroll'
import About from '../components/About';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import ProjectSection from '../components/ProjectSection';
import Arrow from '../images/arrow-down.svg';
import '../styles/animations.css'

const HeroHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: -1;

  h1 {
    color: ${props => props.theme.colors.textInvert};
  }

  h1 > div.hyphen-wrapper {
    display: inline-block;
  }

  .gatsby-image-wrapper {
    position: static !important;
    > div {
      position: absolute !important;
      left: 0;
      top: 0;
    }
  }

  span {
    background-color: #fff;
    width: 8.333333333333333rem;
    height: 0.1904761904761905rem;
    display: inline-block;
    vertical-align: middle;
    animation: hyphen 5s 4s both infinite alternate cubic-bezier(0.45, 0.05, 0.55, 0.95);
  }

  @media (max-width: 800px) {
    
  h1 > div.hyphen-wrapper {
    display: block;
  }
  }
`;

const Nav = styled.div`
  position: fixed;
  top:32px;
  left: 50%;
  transform: translate(-50%, 0);

  z-index: 100;
`;

const BackToTop = styled.div`
  width: 50px;
  height: 50px;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  opacity: 0;

  position: fixed;
  bottom: 80px;
  right: 32px;
  z-index: 100;
  transition: opacity 250ms ease-in-out;

  & .button-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    border-radius: 100px;
    transition: all 100ms ease-out;
  }

  & span {
    z-index: 101;
    cursor: pointer;
    & :hover {
      text-decoration: underline;
    }
  }

  & :hover > .button-background {
    cursor: pointer;
    transform: scale(1.1)
  }

  & :hover > .btt-label {
    text-decoration: underline;
  }
`
// scroll back to top function
const backToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  })
}

const IndexPage = ({
  data: {
    allPrismicHero,
    allPrismicProject,
  },
}) => {

// vatiables setup
  const scrollY = useScrollPosition()
  const home = allPrismicHero.edges[0].node.data
  const projects = allPrismicProject.nodes

  // Similar to componentDidMount and componentDidUpdate:  
  useEffect(() => {
    const headerTag = document.querySelector("header")
    const arrowTag = document.querySelector("img.arrow")
    const navTag = document.querySelector("div.nav-wrapper")
    const introTag = document.querySelector("p.intro")
    const bttButton = document.querySelector("div.button-top")

    const easing = x => x * x * x 

    headerTag.style.opacity = 1 - easing(scrollY / 500);
    arrowTag.style.opacity = 1 - easing(scrollY / 200);
    introTag.style.opacity = 1 - easing(scrollY / 500);
    scrollY > 1500 ? bttButton.style.opacity = 1 :  bttButton.style.opacity = 0

    // change navigation color on scrolling position
    scrollY > 400 ? navTag.classList.remove("navlink") : navTag.classList.add("navlink")
    scrollY > 400 ? navTag.classList.add("navlinkfilter") : navTag.classList.remove("navlinkfilter")
    });

  return (
  <Layout>
    <Nav className="nav-wrapper"><a href="#about">About</a><a href="#contact">contact</a><a href="#work">work</a></Nav>

    <HeroHeader>
      <Img className="heroImage" fluid={home.hero_image.localFile.childImageSharp.fluid} alt={"Hero image Luzia Johow Filmmaker Editor"} />
      <h1>{home.title_left.text} <div className="hyphen-wrapper"><span></span></div> {home.title_right.text}</h1>
      <p className="intro">{home.infotext.text}</p>
      <img className="arrow" src={Arrow} alt="Downwards arrow notification" />
    </HeroHeader>

    <div id="work"></div>
    {projects.map(project => {
      return (
        <ProjectSection
          uid={project.id}
          key={project.id}
          title={project.data.project_title.text}
          body={project.data.body}
        />
      )
    })}

    <About className="about" />
    <Footer className="footer" />
    <BackToTop className="button-top" onClick={() => backToTop()}><div className="button-background"></div><span className="btt-label">Top</span></BackToTop>
  </Layout>
)};

export default IndexPage;

IndexPage.propTypes = {
  data: PropTypes.shape({
    allPrismicHero: PropTypes.object.isRequired,
    allPrismicProject: PropTypes.object.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query IndexQuery {
    allPrismicHero {
      edges {
        node {
          data {
            infotext {
              text
            }
            hero_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1440, maxHeight: 800, quality: 90, cropFocus: ENTROPY) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            title_left {
              text
            }
            title_right {
              text
            }
          }
        }
      }
    }
    allPrismicProject(sort: {fields: last_publication_date, order: DESC}) {
      nodes {
        data {
          body {
            ... on PrismicProjectBodyBeschreibung {
              id
              primary {
                desc_size
                desc {
                  html
                }
              }
              slice_type
            }
            ... on PrismicProjectBodyBild {
              id
              slice_type
              primary {
                image {
                  localFile {
                    childImageSharp {
                      fluid(quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  alt
                }
                image_size
              }
            }
            ... on PrismicProjectBodyCredits {
              id
              slice_type
              primary {
                project_credits {
                  html
                }
                credits_size
              }
            }
            ... on PrismicProjectBodySpacer {
              id
              slice_type
              primary {
                spacer_size
              }
            }
          }
          project_title {
            text
            html
          }
        }
        id
      }
    }
  }
`;
