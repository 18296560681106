import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import styled from "react-emotion";

const GridSection = styled.section`
  margin-bottom: 10rem;
  padding: 0 160px;

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(10% - 1rem), 1fr));
    grid-gap: 1rem;
  }
  .grid > div {
    line-height: 0;
  }

  div.grid-item-1 {
    grid-column: auto;
  }

  div.grid-item-2 {
    grid-column: auto / span 2;
  }

  div.grid-item-2-end {
    grid-column: -3 / -1;
  }

  div.grid-item-3 {
    grid-column: auto / span 3;
  }

  div.grid-item-4 {
    grid-column: auto / span 4;
  }

  div.grid-item-5 {
    grid-column: auto / span 5;
  }

  div.grid-item-full-width {
    grid-column: 1 / -1;
  }

  .credits,
  .beschreibung {
    line-height: initial;
  }

  .project-title {
    position: relative;
    top: 50px;
    left: -96px;
    font-style: normal;
    font-weight: 300;
    font-size: 160px;
    line-height: 0.85;
    // hyphens: auto;
    margin: 0;
    padding: 0;
    font-feature-settings: "rvrn" on;
    z-index: 1;
    pointer-events: none;
  }

  .credits-wrapper {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    margin: 0;
  }

  .description-wrapper {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    margin: 0;
  }

  .credits-wrapper a,
  .description-wrapper a {
    text-decoration: underline;
  }

  .credits-wrapper a:hover,
  .description-wrapper a:hover {
    color: #e55a88;
  }

  @media (max-width: 800px) {
    margin-bottom: 5.714285714285714rem;
    padding: 0 16px;

    .grid-item-5.spacer {
      display: none;
    }

    .grid {
      grid-template-columns: repeat(auto-fit, minmax(calc(20% - 1rem), 1fr));
    }

    .project-title {
      font-size: 112px;
      top: 44px;
      left: -8px;
    }
  }
`;

// select iteme style depending on retrived data
const gridSize = (size) => {
  switch (size) {
    case "1 Spalten":
      return "grid-item-1 ";
      break;
    case "2 Spalten":
      return "grid-item-2 ";
      break;
    case "3 Spalten":
      return "grid-item-3 ";
      break;
    case "4 Spalten":
      return "grid-item-4 ";
      break;
    case "5 Spalten":
      return "grid-item-5 ";
      break;
    case "100%":
      return "grid-item-full-width ";
      break;
    case "2x 50%":
      return "grid-item-full-width ";
      break;
    default:
      return "";
  }
};

const ProjectSection = ({ uid, title, body }) => {
  return (
    <GridSection key={uid}>
      <div className="grid">
        <div className="grid-item-full-width">
          <h2 className="project-title">{title}</h2>
        </div>
        {body.map((elm, i) => {
          switch (elm.slice_type) {
            case "bild":
              return (
                <div
                  className={gridSize(elm.primary.image_size) + elm.slice_type}
                  key={elm.slice_type + " " + i}
                >
                  <Img
                    fluid={elm.primary.image.localFile.childImageSharp.fluid}
                    alt={elm.primary.image.alt}
                  />
                </div>
              );

            case "spacer":
              return (
                <div
                  className={gridSize(elm.primary.spacer_size) + elm.slice_type}
                  key={elm.slice_type + " " + i}
                ></div>
              );

            case "beschreibung":
              return (
                <div
                  className={gridSize(elm.primary.desc_size)}
                  key={elm.slice_type + " " + i}
                >
                  <div
                    className="description-wrapper"
                    dangerouslySetInnerHTML={{ __html: elm.primary.desc.html }}
                  />
                </div>
              );

            case "credits":
              return (
                <div
                  className={gridSize(elm.primary.credits_size)}
                  key={elm.slice_type + " " + i}
                >
                  <div
                    className="credits-wrapper"
                    dangerouslySetInnerHTML={{
                      __html: elm.primary.project_credits.html,
                    }}
                  />
                </div>
              );

            default:
              return "";
          }
        })}
      </div>
    </GridSection>
  );
};

export default ProjectSection;

ProjectSection.propTypes = {
  uid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.array.isRequired,
};
