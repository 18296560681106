import { div } from "grid-emotion";
import React from "react";
import styled from "react-emotion";
import Portrait from "../images/Portrait_Luzia_Johow.jpg";

const GridSection = styled.section`
  margin-bottom: 10rem;
  padding: 0 160px;

  .grid {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 1rem;
  }

  div.about-me {
    font-family: "Inter", "Cera Pro", "Mazius Review", Arial, Helvetica,
      sans-serif;
    grid-column: 2 / span 2;
  }

  div > h3 {
    font-family: "Inter", "Cera Pro", "Mazius Review", Arial, Helvetica,
      sans-serif;
    font-size: 2.285714285714286rem;
  }

  div.portrait {
    grid-column: auto / span 2;
  }

  .about-text {
    margin-top: calc(80px - 1rem);
    grid-column: 5 / span 5;
    grid-row: 2;
  }

  .get-in-touch {
    margin-top: 139px;
    margin-top: calc(160px - 1rem);
    grid-column: 3 / span 6;
    grid-row: 3;
  }

  .contact {
    grid-column: 3 / span 6;
    grid-row: 4;
  }

  .disclaimer {
    margin-top: 139px;
    margin-top: calc(160px - 1rem);
    grid-column: 3 / span 6;
    grid-row: 5;
    font-size: 0.6666666666666667rem;
  }

  .disclaimer > a,
  .contact > a {
    font-weight: bold;
  }

  .disclaimer > a:hover,
  .contact > a:hover {
    text-decoration: underline;
  }

  .dev-credits {
    margin-top: 1rem;
    grid-column: 3 / span 6;
    grid-row: 6;
    font-size: 0.6666666666666667rem;

    a {
      transition: all 100ms ease-in-out;
      &:hover {
        font-size: 0.7rem;
        font-weight: 700;
      }
    }
  }

  @media (max-width: 800px) {
    margin-bottom: 5.714285714285714rem;
    padding: 0 1rem;

    .grid {
      grid-template-columns: repeat(5, 1fr);
    }

    .grid-item-5.spacer {
      display: none;
    }

    div.about-me {
      grid-column: 1 / span 3;
    }

    div.portrait {
      grid-column: auto / span 2;
    }

    .about-text {
      margin-top: calc(60px - 1rem);
      grid-column: 1 / span 5;
      grid-row: 2;
    }

    .get-in-touch {
      margin-top: calc(120px - 1rem);
      grid-column: 1 / span 5;
      grid-row: 3;
    }

    .contact {
      grid-column: 1 / span 5;
      grid-row: 4;
    }

    .disclaimer {
      margin-top: calc(120px - 1rem);
      grid-column: 1 / span 5;
      grid-row: 5;
    }

    .dev-credits {
      grid-column: 1 / span 5;
      grid-row: 6;
    }
  }
`;

const About = () => {
  const year = new Date().getFullYear();
  return (
    <GridSection>
      <div className="grid">
        <div id="about" className="about-me">
          <h3>About Me</h3>
        </div>
        <div className="portrait">
          <img src={Portrait} alt="Portrait Luzia Johow" />
        </div>
        <div className="about-text">
          Luzia Johow, born 1995 in Burgenland (AT). 2016 Matura at the
          Ortweinschule Graz (focus on film & multimedia art). Then internship
          at Nightshift (post-production) in Paris. From 2017 to 2022 she
          studied Editing in Michael Hudeceks Class at Film academy Vienna,
          followed by a Master of film at the KASK Ghent. She directed music
          videos and various short films. Participation in numerous film
          productions, mostly in post-production and sound.
        </div>
        <div id="contact" className="get-in-touch">
          <h3>Get in touch</h3>
        </div>
        <div className="contact">
          International: +43 664 73442409
          <br />
          Email: <a href="mailto:lj@luziajohow.com">lj@luziajohow.com</a>
        </div>
        <div className="disclaimer">
          Credits:
          <br />
          Heading font by David Einwaller get it{" "}
          <a
            href="https://thedesignersfoundry.com/morion"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .<br />
          All rights reserved by Luzia Johow.
        </div>
        <div className="dev-credits">
          <a href="https://www.vfxjohow.io" target="_blank" rel="noreferrer">
            &#60;&#47; developed by V.F.X. Johow &#62;
          </a>
        </div>
      </div>
    </GridSection>
  );
};

export default About;
